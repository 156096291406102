import mixpanel from "mixpanel-browser";
import { applyNewBMUIPatches } from "./patches/new-bm-ui";
import { doMigrateInstance } from "./migrations";
import { miawHandlers } from "./miaw";

interface Config {
    infoUrl: string;
    keepaliveUrl: string;
    companionUrl: string;
    mixpanelToken: string;
    sandbox: string;
    additionalScripts?: string;
}

interface CompanionSettings {
    enableBMKeepAlive?: boolean;
    disableAnalytics?: boolean;
}

interface RuntimeInfo {
    username: string;
    siteId: string;
}

declare global {
    interface Window {
        __qlabsConfig: Config;
    }
}
export {};

console.log("[QLABS] Companion Script Loaded");

const INTERVAL = 30000;

// Function to fetch data from the URL
async function keepalive(keepaliveUrl: string) {
    try {
        const response = await fetch(keepaliveUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
        });
        console.log("[QLABS] BM KeepAlive");
    } catch (error) {
        console.error(
            "[QLABS] Error with BM keep alive; are you logged into business manager?",
            error,
        );
    }
}

var keepAliveInterval;

function getCompanionConfig(): CompanionSettings {
    const config = JSON.parse(
        window.localStorage.getItem("qlabs-companion-config") ?? "{}",
    );
    return config;
}

// Set up the interval to fetch data
function setupKeepAliveInterval() {
    const config = getCompanionConfig();
    if (window?.__qlabsConfig?.keepaliveUrl && config.enableBMKeepAlive) {
        keepAliveInterval = setInterval(
            () => keepalive(window.__qlabsConfig.keepaliveUrl),
            INTERVAL,
        );
    } else {
        console.log("[QLABS] BM KeepAlive Disabled");
    }
}

var runtimeInfo: RuntimeInfo;
var runtimeInfoPromise: Promise<RuntimeInfo>;
async function getRuntimeInfo() {
    if (runtimeInfo) {
        return runtimeInfo;
    }
    if (!runtimeInfoPromise) {
        runtimeInfoPromise = fetch(window.__qlabsConfig.infoUrl)
            .then((response) => response.json())
    }

    runtimeInfo = await runtimeInfoPromise;
    return runtimeInfo;
}

async function setupAnalyticsTracking() {
    const config = getCompanionConfig();

    if (config.disableAnalytics) {
        return;
    }

    var info: RuntimeInfo = {
        username: "storefront",
        siteId: "",
    };

    try {
        info = await getRuntimeInfo();
    } catch (error) {
        // not logged into BM
    }

    mixpanel.init(window.__qlabsConfig.mixpanelToken);

    // for a url path like /on/demandware.store/Sites-Site/default/ViewBM-Home
    // the controller with be ViewBM-Home; extract the controller name
    // if the pattern matches /on/demandware.store/Sites-
    const controller = window.location.pathname.match(
        /\/on\/demandware.store\/Sites-[^\/]*\/.+\/([^\/]*)/,
    );
    if (controller) {
        // add controller to body element
        document.body.setAttribute("data-controller", controller[1]);
        mixpanel.register({ controller: controller[1], context: "bm" });
    } else {
        // get from SFRA (should we just make this a config?)
        mixpanel.register({
            context: "storefront",
            controller:
                document
                    .querySelector("[data-action]")
                    ?.getAttribute("data-action") ?? "unknown",
        });
    }

    if (info.username) {
        mixpanel.identify(info.username);
    }
    mixpanel.register({
        sandbox: window.__qlabsConfig.sandbox,
    });
    mixpanel.track_pageview(
        { source: "companion" },
        { event_name: "B2CE Page View" },
    );
}

/**
 * Apply fixes to BM
 */
async function setupPatches() {
    await applyNewBMUIPatches();
}

async function addAdditionalScripts() {
    // check if we are loaded in business manager (Sites-Site is in the url)
    if (!document.location.pathname.includes("Sites-Site")) {
        return;
    }

    const additionalScripts = window.__qlabsConfig.additionalScripts;
    const info = await getRuntimeInfo();
    console.log("[QLABS] Site ID:", info.siteId);

    if (additionalScripts) {
        const div = document.createElement("div");
        div.innerHTML = additionalScripts;

        // ensure script tags are executed
        div.querySelectorAll("script").forEach((script) => {
            const newScript = document.createElement("script");
            if (script.src.includes('initEmbeddedMessaging') || script.innerHTML.includes('initEmbeddedMessaging')) {
                miawHandlers(info.siteId);
            }
            newScript.text = script.innerHTML;
            if (script.src) {
                newScript.src = script.src;
            }
            if (script.onload) {
                newScript.onload = script.onload;
            }
            document.body.appendChild(newScript);
        });
    }
}

setupAnalyticsTracking();
setupKeepAliveInterval();
setupPatches();
doMigrateInstance();
addAdditionalScripts();
